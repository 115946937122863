const ROOTS = {
  AUTH: '/auth',
  STRATEGIES: '/strategies',
  PROFILE: '/profile',
  PRICING: '/pricing',
  ONBOARDING: '/onboarding',
  SUCCESS: '/success',
  OPTIONS: '/subscription-options',
  CREATECAMP: '/set-up-lead-gen-camp',
  CHIEFMO: 'chief-marketing-officer',
  SEO: 'seo-manager',
  CONNECT_MARKETERS: 'connect-marketers',
  TASKS: '/tasks',
};

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    emailConfirmation: `${ROOTS.AUTH}/email-confirmation`,
    changePassword: `${ROOTS.AUTH}/reset-password`,
    successChangePswd: `${ROOTS.AUTH}/success-change-pswd`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
  },
  // STRATEGIES
  strategies: {
    root: ROOTS.STRATEGIES,
    one: `${ROOTS.STRATEGIES}/one`,
    create: `${ROOTS.STRATEGIES}/create`,
    content: `${ROOTS.STRATEGIES}/content`,
    segments: `${ROOTS.STRATEGIES}/segments`,
    firstTweets: `${ROOTS.STRATEGIES}/first-tweets`,
    group: {
      root: `${ROOTS.STRATEGIES}/group`,
      five: `${ROOTS.STRATEGIES}/group/five`,
      six: `${ROOTS.STRATEGIES}/group/six`,
    },
  },
  // PROFILE
  profile: {
    general: `${ROOTS.PROFILE}/general`,
    notifications: `${ROOTS.PROFILE}/notifications`,
    billing: `${ROOTS.PROFILE}/billing`,
    channels: `${ROOTS.PROFILE}/channels`,
    cancelSubscription: `${ROOTS.PROFILE}/cancel-subscription`,
  },
  // PRICING
  pricing: {
    table: `${ROOTS.PRICING}/table`,
    info: `${ROOTS.PRICING}/info`,
  },
  // ONBOARDING
  onboarding: {
    strategy: `${ROOTS.ONBOARDING}/strategy`,
    content: `${ROOTS.ONBOARDING}/content`,
    sharing: `${ROOTS.ONBOARDING}/sharing`,
  },
  // SUCCESS
  success: {
    root: ROOTS.SUCCESS,
    leadgen: `${ROOTS.SUCCESS}/leadgen`,
  },
  // SUBSCRIPTION OPTIONS
  options: {
    root: ROOTS.OPTIONS,
  },
  // CREATE LEAD GENERATION CAMPAIGN
  createCamp: {
    root: ROOTS.CREATECAMP,
    connectAccount: `${ROOTS.CREATECAMP}/connect-channel`,
  },
  chiefMO: {
    root: ROOTS.CHIEFMO,
  },
  seo: {
    auditReport: `${ROOTS.SEO}/audit-report`,
  },
  connectMarketers: {
    root: ROOTS.CONNECT_MARKETERS,
  },
  tasks: {
    root: ROOTS.TASKS,
  },
};
