/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { bgBlur } from 'src/theme/css';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSettingsContext } from 'src/components/settings';
import { Box, Divider, useMediaQuery } from '@mui/material';

import { useAuthContext } from 'src/auth/hooks';
import { getStrategies } from 'src/utils/api';
import { paths } from 'src/routes/paths';
import { Link } from 'react-router-dom';

import { setSession } from 'src/auth/context/jwt/utils';
import { HEADER } from '../config-layout';
import { AccountPopover } from '../_common';

import './styles/tab.css';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const isMobileScreen = useMediaQuery('(max-width:1200px)');

  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const { setStrategiesContext, user } = useAuthContext();

  const currentPath = window.location.pathname;

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getStrategies();
        setStrategiesContext(res.data.data);
      } catch (e) {
        console.log(e);
      }
    };

    if (currentPath === '/strategies/content') {
      getData();
    }
  }, [currentPath]);

  useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken && user !== null) {
      setSession(accessToken, user);
    }
  }, [user]);

  const renderContent = (
    <>
      <Box
        display="flex"
        sx={{
          gap: isMobileScreen ? '12px' : { xs: '10px', sm: '32px' },
          marginLeft: isMobileScreen ? '24px' : '0px',
        }}
        alignItems="center"
      >
        <Box>
          {user?.subscriptions.smm.priceID !== 2 ? (
            <Box sx={{ width: isMobileScreen ? '120px' : 'auto' }}>
              <Box
                component="img"
                alt="Logo"
                src="/logo/logo-header-new.png"
                width="120px"
                height="25px"
              />
            </Box>
          ) : (
            <Box component={Link} to={paths.strategies.root}>
              <Box sx={{ width: isMobileScreen ? '120px' : 'auto' }}>
                <Box
                  component="img"
                  alt="Logo"
                  src="/logo/logo-header-new.png"
                  width="120px"
                  height="25px"
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        gap="24px"
        spacing={{ xs: '10px', sm: 3 }}
      >
        <Box
          sx={{
            position: isMobileScreen ? 'fixed' : 'static',
            left: isMobileScreen ? '16px' : '0px',
          }}
        >
          <AccountPopover />
        </Box>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { xs: '24px', md: 5 },
          gap: { xs: '10px', sm: '0px' },
        }}
      >
        {renderContent}
      </Toolbar>
      {isMobileScreen && <Divider />}
    </AppBar>
  );
}
