import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

import ProfileLayout from 'src/layouts/dashboard/profile-layout';

// ----------------------------------------------------------------------

const General = lazy(() => import('src/pages/profile/general'));
const Channels = lazy(() => import('src/pages/profile/channels'));
const Notifications = lazy(() => import('src/pages/profile/notifications'));
const Billing = lazy(() => import('src/pages/profile/billing'));
const CancelSubscription = lazy(() => import('src/pages/profile/cancel-subscription'));

// ----------------------------------------------------------------------

export const profileRoutes = [
  {
    path: 'profile',
    element: (
      <AuthGuard>
        <ProfileLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </ProfileLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'general', element: <General /> },
      { path: 'notifications', element: <Notifications /> },
      { path: 'billing', element: <Billing /> },
      { path: 'channels', element: <Channels /> },
      { path: 'cancel-subscription', element: <CancelSubscription /> },
    ],
  },
];
