import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
import Guard from 'src/layouts/dashboard/guard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import Redirection from 'src/layouts/dashboard/redirection';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/strategies/marketing-strategy'));
const StrategyGeneration = lazy(() => import('src/pages/strategies/strategy-generation'));
const Content = lazy(() => import('src/pages/content/content'));
const Segments = lazy(() => import('src/pages/segments/segments'));

export const dashboardRoutes = [
  {
    path: 'strategies',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Guard>
            <Redirection>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </Redirection>
          </Guard>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'create', element: <StrategyGeneration /> },
      { path: 'content', element: <Content /> },
      { path: 'segments', element: <Segments /> },
    ],
  },
];
