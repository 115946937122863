import { BatchesStat } from './lead-gen/statistic/types';

export type Message = {
  Description: string;
  Message: string;
  Name: string;
};

export type Messages = Message[];

export type Channels = string[];

export type TargetAudience = {
  Age: string;
  'Apps Used': string;
  'Buying Habits': string;
  'Climatic Characteristics': string;
  'Cultural Features': string;
  Education: string;
  Employment: string;
  'Gadgets Used': string;
  Gender: string;
  Habits: string;
  Income: string;
  Infrastructure: string;
  Interests: string;
  'Level of Life Satisfaction': string;
  'Marital Status': string;
  'Marketing Behavior': string;
  Motivations: string;
  'Place of residence': string;
  'Social Status': string;
  'Standard of Living': string;
  'Type of Settlement': string;
  'Used Sites': string;
  Values: string;
};

export type CompaniesParams = {
  Challenges: string;
  'Company size/Employees count': string;
  'Customer base': string;
  Goals: string;
  Industry: string;
  Location: string;
  Revenue: string;
};

export type Companies = {
  NameICP: string;
  Description: string;
  Parameters: CompaniesParams;
};

export type DecisionMakers = {
  'Corporate buyers behavior': string;
  'Current job title': string;
  'Factors that influence on buying': string;
  Function: string;
  'Name of group': string;
  'Past job title': string;
  'Seniority level': string;
  'Years in current company': string;
  'Years in current position': string;
};

export type Segment = {
  'Communication Concepts': Messages;
  'Marketing Channels': Channels;
  description: string;
  'name icp': string;
  segment: string;
  segmentID: number;
  'Target audience': TargetAudience;
  Companies: Companies;
  'Decision Makers': DecisionMakers;
  status: string;
};

export type Segments = Segment[];

export type RespObject = {
  product: string;
  projectID: number;
  segments: Segments;
  type: string;
  userID: number;
  where: string;
  consents: boolean;
  name: string;
  webSiteUrl?: string;
};

export type Strategy = {
  product: string;
  projectID: number;
  segments: Segments;
  type: string;
  userID: number;
  where: string;
  name: string;
  consents: boolean;
  processing?: boolean;
  webSiteUrl?: string;
  timezone?: string;
};

export type StrategyOnb = Strategy & {
  twitter: Post[];
  linkedin: Post[];
  facebook: Post[];
};

export type Strategies = Strategy[];

export type DataCreateStrategy = {
  projectID?: number;
  product: string;
  where: string;
  type: string;
  regenerate?: boolean;
  strategy_name?: string;
};

export type DownloadDataType = {
  userID: number;
  projectID: number;
  type: string;
};

export type postsCreateData = {
  channel: string;
  projectID: number;
};

export type Post = {
  body: string;
  channel: string;
  plan_date: string;
  project_id: number;
  segment_id: number;
  segment_num: number;
  status: string;
  url_media: string | null;
  user_id: number;
  _id: number;
  url_post: string | null;
  post_type: string;
  length?: string;
  tov_fix?: string;
};

export type TweetsArr = Post[];

export type ModifiedResult = {
  [key: number]: [
    number,
    string,
    string,
    string,
    string | null,
    string,
    string | undefined,
    string | undefined
  ][];
};

export type FileUploaded = {
  lastModified: number;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

export type segmentState = {
  segment1: boolean;
  segment2: boolean;
  segment3: boolean;
};

export type statusState = {
  published: boolean;
  planned: boolean;
};

export type Payment = {
  amount: number;
  created: string;
  currency: string;
  id: string;
  receipt_url: string;
  refunded: boolean;
};

export type Project = {
  project_id: number;
  project_name: string;
};

export type Place = {
  place_id: number;
  place_name: string;
  place_type: string;
  projects: Project[];
};

export type AccountInfo = {
  acc_id: number;
  acc_name: string;
  places: Place[];
  acc_one: boolean;
};

export type ChannelsAccounts = {
  twitter?: AccountInfo[];
  facebook?: AccountInfo[];
  linkedin?: AccountInfo[];
};

export type NewAccountInfo = {
  acc_id: number;
  acc_name: string;
  places: NewPlace[];
};

export type NewPlace = {
  place_id: number;
  place_name: string;
  place_type: string;
};

export type StrategyAccounts = {
  acc_id: number;
  acc_name: string;
  place_id: number;
  place_name: string;
  place_type: string;
};

export type ChannelStrategyAccount = {
  project_id: number;
  project_name: string;
  twitter?: StrategyAccounts;
  linkedin?: StrategyAccounts;
  facebook?: StrategyAccounts;
};

export type ExtendedPlace = NewPlace & {
  count_projects?: number;
  cur_project?: boolean;
};

export type ExtendedPlaces = ExtendedPlace[];

export type ListPlaces = {
  acc_id: number;
  acc_name: string;
  places: ExtendedPlaces;
  current_project?: boolean;
  acc_one: boolean;
}[];

export type StrategyWithAccounts = {
  twitter?: StrategyAccounts;
  linkedin?: StrategyAccounts;
  facebook?: StrategyAccounts;
  project_id: number;
  project_name: string;
};

export type StrategiesWithAccounts = StrategyWithAccounts[];

export type Source = {
  url: string;
  type: 'blog' | 'news';
  valid?: number;
};

export type SettingsParams = {
  length: string[];
  percent: number;
  tov_desc: string;
  tov_list: string[];
  tov_ind_list: PersonalStyle[];
  urls: Source[];
  number_of_posts: number;
  urls_all: Source[];
  img_style_list: string[];
};

export type SettingsResponse = {
  channel: string;
  params: SettingsParams;
  project_id: number;
  user_id: number;
};

export type Settings = {
  projectID: number;
  channel: string;
  params: SettingsParams;
};

export type PostStatistic = {
  Comments: number;
  Impressions: number;
  Reactions: number;
  Reposts: number;
  id: number;
};

export type PersonalStyle = {
  title: string;
  status: number;
};

export enum Statuses {
  active = 'active',
  inactive = 'inactive',
  empty = 'empty',
  ended = 'ended',
  paused = 'paused',
  finished = 'finished',
}

export type Campaign = {
  mainID: number;
  segmentName: string;
  status: Statuses;
  segmentID: number;
  segmentStatus: string;
  processing?: boolean;
  segment: string;
  campName: string;
  linkNickName: string;
  emailAddress: string;
};

export type CampaignSettings = {
  mainID: number;
  status: string;
  msgApproved: boolean;
  channel: string;
  segmentID: number;
  segmentName: string;
  webSiteUrl: string;
  utps: string[];
  pains: string[];
  channelEmail: string;
  channelRegion: string;
  ownerName: string;
  ownerJobTitle: string;
  ownerCompany: string;
  meetingLink: string;
  emailAddress: string;
  linkProfileUrl: string;
  tov_list: string[];
  tov_ind_list: PersonalStyle[];
  tov_desc: string;
  cases: string;
  reviews: string;
  addons: string;
  campName: string;
};

export type NewCampaignSettings = {
  segmentID: number;
  utps: string[];
  pains: string[];
  emailAddress: string;
  ownerName: string;
  ownerJobTitle?: string;
  ownerCompany: string;
  meetingLink?: string;
  linkProfileUrl: string;
  tov_list: string[];
  tov_ind_list: PersonalStyle[] | [];
  tov_desc: string;
  cases: string;
  reviews: string;
  addons: string;
  webSiteUrl?: string;
};

export enum LeadGenChannels {
  linkedin = 'linkedin',
  email = 'email',
}

export type TactisMsg = {
  number: number;
  channel: string;
  'Connects Sent': string | null;
  Accepted: string | null;
  'Messages Sent': { count: number; str: string } | null;
  Opened: string | null;
  Replied: { count: number; str: string } | null;
};

export type StatTactic = {
  stat_tactic: Record<string, { str: string; count: number } | null>;
  messages: TactisMsg[];
};

export type ChannelDetails = {
  channelAccount: string;
  stage: Stages;
  senderStatus: string;
  sendAddresses: string[];
  senderID: number;
  campID: number;
};

export type CampaignDetails = {
  mainID: number;
  // status: string;
  // channelStatuses: {
  //   email: string;
  //   linkedin: string;
  // };
  // linkedin: ChannelDetails;
  // email: ChannelDetails;
  // segmentName: string;
  segmentID: number;
  summary: Record<string, { str: number }>;
  batchesStat: BatchesStat;
};

export type CommonCampaignDetails = {
  segmentName: string;
  segmentID: number;
  linkedin: ChannelDetails;
  email: ChannelDetails;
  channelStatuses: {
    email: Statuses;
    linkedin: Statuses;
  };
  status: Statuses;
  autoRunBatch: boolean;
  campName: string;
};

export type Stages = {
  Setting: number;
  Prospecting: number;
  'Warming Up': number;
  'Activate outreach': number;
  Finish: number;
};

export type CampMessage = {
  msgNumber: number;
  msgText: string;
  status: string;
  channel: string;
  totalNum: number;
  msgID: number;
};

export type CampMessageWithChannel = CampMessage & {
  channel: string;
};

export type Tactic = {
  tacticID: string;
  tacticNum: number;
  msgs: CampMessage[];
};

export type Sender = {
  channel?: string;
  senderLogin?: string;
  senderPsw?: string;
  countryCode: string;
  tmpCode?: string;
  firstName?: string;
  lastName?: string;
};

export type RequestedSender = {
  senderID: number;
  senderLogin: string;
  senderRegion: string;
  senderStatus: string;
  countryCode: string;
  countryName: string;
  countriesList: Record<string, string>[];
};

export type Thread = {
  content: string;
  countMsg: number;
  date: string;
  from: string;
  isUnread: boolean;
  msgID: number;
  replyUrl: string;
  sender: string;
  subject: string;
  threadID: number;
  to: string;
  channel: string;
  isSQL: boolean;
  senderEmails: string[];
};

export type FormValuesProps = {
  clientType: string;
  productDescription: string;
  salePlace: string;
};

export type Task = {
  task_id: string;
  status: string;
  obj_kind: string;
  obj_id: number;
  created_at: string;
};

export type CustomError = {
  msg?: string;
  message?: string;
};

export type AuditReportObj = {
  name: string;
  icon: React.ReactNode;
  description: string;
  result: string;
  link: string;
  recommendation: string;
};

export enum ExitRoles {
  smm = 'smm',
  leadgen = 'leadgen',
}

export enum TaskTypes {
  CREATE_NEW_BATCH = 'CREATE_NEW_BATCH',
}

export enum TaskStatus {
  ARCHIVED = 'archived',
  ACTIVE = 'active',
}

export enum Importance {
  NECESSARY = 'necessary',
  RECOMMENDATION = 'recommendation',
  AI_MARKETER = 'ai_marketer',
}

export type ServerTask = {
  taskID: number;
  taskType: TaskTypes;
  status: TaskStatus;
  importance: Importance;
  strategyID: number;
  mainID?: number;
  segmentID: number;
  segment: string;
};

export type CampMessageWithTucticNum = CampMessage & {
  tacticNum: number;
};
