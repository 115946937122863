// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        justifyContent: 'center',
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      position="relative"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/authPng/bg.webp',
        }),
      }}
    >
      <Typography
        variant="h2"
        sx={{ maxWidth: 480, textAlign: 'center', mt: { xs: 0, lg: '74px' } }}
      >
        {title || 'Your marketing ally awaits'}
      </Typography>

      <Box sx={{ position: 'relative', mt: '-88px' }}>
        <Box
          component="img"
          alt="auth"
          src={image || '/assets/illustrations/character_pose17_1.webp'}
        />

        <Box
          component="img"
          alt="auth page image1"
          src="/assets/authPng/component_1.webp"
          sx={{
            position: 'absolute',
            top: '26.5%',
            left: '10%',
            width: '11%',
          }}
        />

        <Box
          component="img"
          alt="auth page image2"
          src="/assets/authPng/component_2.webp"
          sx={{
            position: 'absolute',
            top: '82.7%',
            left: '17.6%',
            width: '11.5%',
          }}
        />

        <Box
          component="img"
          alt="auth page image3"
          src="/assets/authPng/component_3.webp"
          sx={{
            position: 'absolute',
            top: '57.5%',
            right: '10.5%',
            width: '10.5%',
          }}
        />
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {upMd && renderSection}

      {renderContent}
    </Stack>
  );
}
