import { HOST_API } from 'src/config-global';
import {
  Companies,
  DataCreateStrategy,
  DecisionMakers,
  DownloadDataType,
  ExitRoles,
  NewCampaignSettings,
  PersonalStyle,
  Sender,
  TargetAudience,
  postsCreateData,
} from 'src/sections/types';
import axios, { API_ENDPOINTS } from './axios';

function getHeadersWithToken() {
  const token = sessionStorage.getItem('accessToken');

  return {
    'Content-Type': 'application/json',
    Authorization: token || '',
  };
}

export async function downloadFile(data: DownloadDataType): Promise<Response | undefined> {
  const endpoint = API_ENDPOINTS.strategies.download;

  try {
    const response = await fetch(`${HOST_API}${endpoint}`, {
      method: 'POST',
      headers: getHeadersWithToken(),
      body: JSON.stringify(data),
    });
    return response;
  } catch (e) {
    throw new Error(e.message);
  }
}

export async function changeMsgs(
  userID: string,
  projectID: number,
  segmentID: number,
  msg_name: string,
  message: string
) {
  const data = {
    userID,
    projectID,
    segmentID,
    msg_name,
    message,
  };

  const res = await axios.post(API_ENDPOINTS.strategies.editMsgs, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getStrategies() {
  const res = await axios.get(API_ENDPOINTS.strategies.getStrategies, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function createStrategy(data: DataCreateStrategy) {
  const res = data.regenerate
    ? await axios.post(API_ENDPOINTS.strategies.regenerate, data, {
        headers: getHeadersWithToken(),
      })
    : await axios.post(API_ENDPOINTS.strategies.create, data, { headers: getHeadersWithToken() });
  return res;
}

export async function saveStrategy(data: DataCreateStrategy) {
  const res = await axios.post(API_ENDPOINTS.strategies.save, data, {
    headers: getHeadersWithToken(),
  });

  return res;
}

export async function postsCreate(data: postsCreateData): Promise<Response | undefined> {
  const endpoint = API_ENDPOINTS.post.create;

  try {
    const response = await fetch(`${HOST_API}${endpoint}`, {
      method: 'POST',
      headers: getHeadersWithToken(),
      body: JSON.stringify(data),
    });
    return response;
  } catch (e) {
    throw new Error(e.message);
  }
}

// TWEETS

export async function getPostsList(
  projectID: number,
  channel?: string,
  fromDate?: string,
  toDate?: string,
  status?: string
) {
  const data = {
    projectID,
    channel,
    status,
    fromDate,
    toDate,
  };

  const res = await axios.get(API_ENDPOINTS.posts.list, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function generatePosts(
  projectID: number,
  channel: string,
  number_of_posts?: number,
  percent?: number,
  length?: string[],
  tov_list?: string[],
  tov_ind_list?: PersonalStyle[],
  tov_desc?: string,
  urls?: {
    url: string;
    type: 'blog' | 'news';
  }[],
  img_style_list?: string[]
) {
  const data = {
    projectID,
    channel,
    params: {
      number_of_posts,
      percent,
      length,
      tov_list,
      tov_ind_list,
      tov_desc,
      urls,
      img_style_list,
    },
  };

  const res = await axios.post(API_ENDPOINTS.posts.generate, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function editTweet(
  action: string,
  postID: number,
  body?: string,
  url_media?: string,
  title?: string,
  plan_date?: string | null
) {
  const data = {
    posts: [
      {
        action,
        postID,
        body,
        url_media,
        title,
        plan_date,
      },
    ],
  };

  const res = await axios.post(API_ENDPOINTS.posts.edit, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function upLoadImage(file: File) {
  const formData = new FormData();
  formData.append('file', file);

  const res = await axios.post(API_ENDPOINTS.posts.uploadImage, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: sessionStorage.getItem('accessToken') || '',
    },
  });
  return res;
}

export async function setConsents(channel: string, consent1: string, concent2: string) {
  const data = {
    channel,
    texts: [consent1, concent2],
  };

  const res = await axios.post(API_ENDPOINTS.users.sendConsent, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function checkConsents(channel: string) {
  const data = {
    channel,
  };

  const res = await axios.get(API_ENDPOINTS.users.checkConsents, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function checkUser(session_id?: string) {
  const data = {
    session_id,
  };
  const res = await axios.get(API_ENDPOINTS.users.checkUser, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function cancelSubscription(
  id: number,
  reasonCancellation: string,
  competitor?: string,
  freeReason?: string
) {
  const data = {
    reasonCancellation,
    competitor,
    freeReason,
  };

  const res = await axios.post(`${API_ENDPOINTS.subscriptions.cancel}/${id}`, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function pauseSubscription(id: number, datePauseEnd: string) {
  const data = {
    datePauseEnd,
  };

  const res = await axios.post(`${API_ENDPOINTS.subscriptions.pause}/${id}`, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function resumeSubscription(id: number) {
  const res = await axios.post(
    `${API_ENDPOINTS.subscriptions.resume}/${id}`,
    {},
    {
      headers: getHeadersWithToken(),
    }
  );
  return res;
}

export async function getDiscount(id: number) {
  const data = {};

  const res = await axios.post(`${API_ENDPOINTS.subscriptions.getDiscount}/${id}`, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function editProfile(
  firstname: string,
  lastname: string,
  email: string,
  media: string | null
) {
  const data = {
    firstname,
    lastname,
    email,
    media,
  };

  const res = await axios.post(API_ENDPOINTS.users.edit, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function editTipsSet(tipName: string, tipValue: number) {
  const data = {
    tips_set: {
      [tipName]: tipValue,
    },
  };

  const res = await axios.post(API_ENDPOINTS.users.saveTips, data);
  return res;
}

export async function changePassword(password: string) {
  const data = {
    password,
  };

  const res = await axios.post(API_ENDPOINTS.users.editPassword, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getPayments() {
  const res = await axios.get(API_ENDPOINTS.subscriptions.invoices, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function deleteUser() {
  const res = await axios.post(
    API_ENDPOINTS.users.delete,
    {},
    {
      headers: getHeadersWithToken(),
    }
  );
  return res;
}

export async function getNotifications() {
  const res = await axios.get(API_ENDPOINTS.notifications.get, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function setNotifications(notification: string, value: boolean) {
  const data = {
    notification,
    value,
  };
  const res = await axios.post(API_ENDPOINTS.notifications.set, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getURL(id: number) {
  const res = await axios.get(`${API_ENDPOINTS.subscriptions.payment}/${id}/payment/url`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function skipOnboarding() {
  const res = await axios.post(
    API_ENDPOINTS.users.skipOnboarding,
    {},
    {
      headers: getHeadersWithToken(),
    }
  );
  return res;
}

export async function countPosts(projectID: number) {
  const data = {
    projectID,
  };
  const res = await axios.get(API_ENDPOINTS.posts.count, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getStripeAttributes(typeSubs?: string) {
  const data = {
    typeSubs,
  };
  const res = await axios.get(API_ENDPOINTS.subscriptions.getAttributes, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getPortalUrl(roleMarketer?: string) {
  const data = {
    roleMarketer,
  };
  const res = await axios.get(API_ENDPOINTS.subscriptions.getPortalUrl, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function createPortal(type: string) {
  const res = await axios.get(`${API_ENDPOINTS.subscriptions.getPortalUrl}/${type}`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

// CONNECT TO SOCIAL MEDIA CHANNELS

export async function connectToSMMChannel(url: string, channel: string) {
  const data = {
    url,
    channel,
  };
  const res = await axios.post(API_ENDPOINTS.socialMediaConnect.connect, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function disconnectSMMChannel(channel: string, accID: number) {
  const data = {
    channel,
    accID,
  };
  const res = await axios.post(API_ENDPOINTS.socialMediaConnect.disconnect, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getAuthUrlSMMChannel(channel: string, type?: string) {
  const data = {
    channel,
    type,
  };
  const res = await axios.get(API_ENDPOINTS.socialMediaConnect.getAuthUrl, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getAccounts(projects: boolean, projectID?: number) {
  const data = {
    projects,
    projectID,
  };

  const res = await axios.get(API_ENDPOINTS.users.accounts, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getStrategyAccounts(projectID?: number) {
  const data = {
    projectID,
  };

  const res = await axios.get(API_ENDPOINTS.strategies.accounts, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function savePage(
  channel: string,
  placeID: number,
  projectIDs?: number[],
  clear?: boolean,
  allAccounts?: boolean
) {
  const data = {
    channel,
    placeID,
    projectIDs,
    clear,
    allAccounts,
  };

  const res = await axios.post(API_ENDPOINTS.socialMediaConnect.savePlace, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function listPlaces(channel: string, projectID: number) {
  const data = {
    channel,
    projectID,
  };

  const res = await axios.get(API_ENDPOINTS.socialMediaConnect.listPlaces, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getSegments(projectID: number) {
  const data = {
    projectID,
  };

  const res = await axios.get(API_ENDPOINTS.strategies.segments, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function saveSegment(
  segmentID: number,
  nameICP: string,
  description: string,
  targetAudience: TargetAudience | {},
  companies: Companies | {},
  decisionMakers: DecisionMakers | {}
) {
  const data = {
    segmentID,
    nameICP,
    description,
    targetAudience,
    companies,
    decisionMakers,
  };

  const res = await axios.post(API_ENDPOINTS.strategies.saveSegment, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function regenerateSegment(segmentID: number, description: string) {
  const data = {
    segmentID,
    description,
  };

  const res = await axios.post(API_ENDPOINTS.strategies.regenerateSegment, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function approveSegment(segmentID: number, approved: boolean) {
  const data = {
    segmentID,
    approved,
  };

  const res = await axios.post(API_ENDPOINTS.strategies.approveSegment, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function mixPanelAnalytics(event: string) {
  const data = {
    event,
  };

  const res = await axios.post(API_ENDPOINTS.analytics.mp, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getSettings(projectID: number, channel: string) {
  const data = {
    projectID,
    channel,
  };

  const res = await axios.get(API_ENDPOINTS.posts.getSettings, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function saveSettings(
  projectID: number,
  channel: string,
  number_of_posts: number,
  percent: number,
  length: string[],
  tov_list: string[],
  tov_ind_list: PersonalStyle[],
  tov_desc: string,
  urls: {
    url: string;
    type: 'blog' | 'news';
  }[],
  img_style_list: string[]
) {
  const data = {
    projectID,
    channel,
    params: {
      number_of_posts,
      percent,
      length,
      tov_list,
      tov_ind_list,
      tov_desc,
      urls,
      img_style_list,
    },
  };
  const res = await axios.post(API_ENDPOINTS.posts.saveSettings, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getPostStatistic(postID: number) {
  const data = {
    postID,
  };

  const res = await axios.get(API_ENDPOINTS.post.statistic, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function verifySite(type: string, url: string, valid: number) {
  const data = {
    type,
    url,
    valid,
  };

  const res = await axios.post(API_ENDPOINTS.posts.verifySite, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function savePersonalStyle(title: string, textSample: string) {
  const data = {
    title,
    textSample,
  };

  const res = await axios.post(API_ENDPOINTS.strategies.savePersonalStyle, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function generateImage(postID: number, text: string) {
  const data = {
    postID,
    text,
  };

  const res = await axios.post(API_ENDPOINTS.posts.generateImage, data, {});
  return res;
}

export async function getDetails(id: number, channel: string) {
  const res = await axios.get(`${API_ENDPOINTS.lg.getStat}/${id}/statistic/${channel}`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getCampaigns(projectID: number) {
  const res = await axios.get(`${API_ENDPOINTS.lg.getCampaigns}/${projectID}/list`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getCampSettings(
  id: number,
  segmentID: number,
  na?: string,
  newBatch?: boolean
) {
  const data = {
    segmentID,
    na,
    newBatch,
  };

  const res = await axios.get(`${API_ENDPOINTS.lg.getStat}/${id}/setting`, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function saveCampSettings(id: number, data: NewCampaignSettings) {
  const res = await axios.post(`${API_ENDPOINTS.lg.getStat}/${id}/setting`, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function changeCampStatus(id: number, newStatus: string, channel: string) {
  const data = {
    newStatus,
  };

  const res = await axios.put(`${API_ENDPOINTS.lg.getStat}/${id}/channel/${channel}`, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function saveMessages(
  id: number,
  channel: string,
  messages: { msgID: number; msgText: string; status: string }[]
) {
  const data = {
    messages,
  };

  const res = await axios.post(`${API_ENDPOINTS.lg.getStat}/${id}/msg/${channel}`, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function postWebsite(segmentID: number, webSiteUrl: string) {
  const data = {
    segmentID,
    webSiteUrl,
    typeResult: 'utp',
  };

  const res = await axios.post(`${API_ENDPOINTS.strategies.postWebsite}`, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function analyzeURL(webSiteUrl: string, typeResult: string) {
  const data = {
    webSiteUrl,
    typeResult,
  };

  const res = await axios.post(`${API_ENDPOINTS.strategies.analyzeURL}`, data);
  return res;
}

export async function postSender(id: number, data: Sender) {
  const res = await axios.post(`${API_ENDPOINTS.lg.sender}/${id}`, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getSender(id: number, na?: string) {
  const data = {
    na,
  };

  const res = await axios.get(`${API_ENDPOINTS.lg.sender}/${id}`, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getSenderStatus(id: number) {
  const res = await axios.get(`${API_ENDPOINTS.lg.sender}/${id}/status`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function disconnectSenderAccount(id: number) {
  const res = await axios.delete(`${API_ENDPOINTS.lg.sender}/${id}`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getReplies(
  id: number,
  channel: string,
  limit?: number,
  offset?: number,
  filter?: string
) {
  const data = {
    limit,
    offset,
    filter,
  };

  const res = await axios.get(`${API_ENDPOINTS.lg.getStat}/${id}/replies/${channel}`, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getUnreadMsgs(id: number, channel: string) {
  const res = await axios.get(`${API_ENDPOINTS.lg.getStat}/${id}/replies/${channel}/countnew`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getThreadHistory(id: number) {
  const res = await axios.get(`${API_ENDPOINTS.lg.thread}/${id}`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getMessages(id: number, channel: string, batchID?: number) {
  const data = {
    batchID,
  };
  const res = await axios.get(`${API_ENDPOINTS.lg.getStat}/${id}/msg/${channel}`, {
    params: data,
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function approveMessages(
  id: number,
  channel: string,
  approved: boolean,
  batchID: number
) {
  const data = {
    approved,
    batchID,
  };

  const res = await axios.put(`${API_ENDPOINTS.lg.getStat}/${id}/msg/${channel}`, data, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getCommonDetails(id: number) {
  const res = await axios.get(`${API_ENDPOINTS.lg.getStat}/${id}/status`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getExitStat(role: ExitRoles) {
  const res = await axios.get(`${API_ENDPOINTS.stat.getStat}/${ExitRoles[role]}`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function requestNewBatch(id: number, segmentNum: number) {
  const data = {
    segmentNum,
  };

  const res = await axios.post(`${API_ENDPOINTS.lg.getStat}/${id}/batch`, data);
  return res;
}

export async function saveAutoRunSettings(id: number, autoRunBatch: boolean) {
  const data = {
    autoRunBatch,
  };

  const res = await axios.put(`${API_ENDPOINTS.lg.getStat}/${id}/auto-run`, data);
  return res;
}

export async function saveCampName(id: number, campName: string) {
  const data = {
    campName,
  };

  const res = await axios.put(`${API_ENDPOINTS.lg.getStat}/${id}/name`, data);
  return res;
}

export async function getLeadgenReport(id: number) {
  const res = await axios.get(`${API_ENDPOINTS.lg.getStat}/${id}/report`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getBatchSnap(mainID: number, batchID: number) {
  const res = await axios.get(`${API_ENDPOINTS.lg.getStat}/${mainID}/batch/${batchID}/snap`, {
    headers: getHeadersWithToken(),
  });
  return res;
}

export async function getChanges(id: number) {
  const res = await axios.get(`${API_ENDPOINTS.lg.getStat}/${id}/batch/changes`, {
    headers: getHeadersWithToken(),
  });
  return res;
}
